import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { Signin } from '../models/admin';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private consts: ConstantsService,
  ) { }

  public GetLang(): any {
    return localStorage.getItem(this.consts.langStr) ?? undefined;
  }
  public SetLang(lang: string): void {
    localStorage.setItem(this.consts.langStr, lang);
  }
  public SetAuthorizationData(data: Signin): void {
    localStorage.setItem(this.consts.authDataLsStr, JSON.stringify(data));
  }
  public GetAuthorizationData(): String {
    let val = localStorage.getItem(this.consts.authDataLsStr) ?? '';
    let tokendata = val != '' ? JSON.parse(val) : null;
    return tokendata ?? tokendata;
  }
  public RemoveAuthorizationData(): void {
    localStorage.removeItem(this.consts.authDataLsStr);
  }

  public SetRemember(username: String) {
    localStorage.setItem(this.consts.rememberLsStr, JSON.stringify(username));
  }
  public GetRemember(): string {
    let val = localStorage.getItem(this.consts.rememberLsStr) ?? '';
    let remember = val != '' ? JSON.parse(val) : null;
    return remember == null ? null : remember;
  }
  public RemoveRemember(): void {
    localStorage.removeItem(this.consts.rememberLsStr);
  }
}