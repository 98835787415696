import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHeaders,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { ConstantsService } from './constants.service';
import { Signin } from '../models/admin';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private router: Router,
    private consts: ConstantsService
  ) { }
  handleError(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      // server side error
      return throwError(err.error);
    } else {
      // client side error
      return throwError(err);
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let data = this.storage.GetAuthorizationData();
    if (!req.headers.getAll('authorization') && data != null) {
      let sg = new Signin(data);
      const modifiedReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
          .set('language', this.storage.GetLang())
          .set('authorization', 'Bearer ' + sg.token)
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept')
      });
      return next.handle(modifiedReq)
        .pipe(tap(() => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.storage.RemoveAuthorizationData();
                this.router.navigateByUrl(this.consts.adminPanelLinkStr);
              }
            }
          }));
    } else {
      const modifiedReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
          .set('language', this.storage.GetLang())
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept')
      });
      return next.handle(modifiedReq)
        .pipe(
          catchError(this.handleError)
        )
    }
  };
}