import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuluy',
  templateUrl: './tuluy.component.html',
  styleUrls: ['./tuluy.component.less']
})
export class TuluyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
