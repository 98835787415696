import { Injectable, Inject } from '@angular/core';
import { API_BASE_URL } from './config';

@Injectable({ providedIn: 'root' })
export class ConstantsService {

    public readonly blogStr: string = 'blog';

    baseUrl: any;
    public readonly langStr = 'language';
    public readonly langMnStr = 'mn';
    public readonly langEnStr = 'en';

    constructor(@Inject(API_BASE_URL) baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public apiUrl(str: string): string {
        return this.baseUrl + '/' + str;
    }

    public routerUrl(str: string): string {
        return '/' + str;
    }
    public readonly adminPanelLinkStr: string = 'ap/signin';

    public readonly signinStr: string = 'signin';
    public readonly rememberLsStr: string = 'RememberUn';
    public readonly authDataLsStr: string = 'Authorization';
    // Api path
    public readonly newslist: string = 'blog/list';
    public readonly news: string = 'blog';
    public readonly banners: string = 'banner/list';
    public readonly banner: string = 'banner';
    public readonly reports: string = 'report-list';
    public readonly report: string = 'report';
    public readonly rules: string = 'rule-list';
    public readonly rule: string = 'rule';
}