import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ConstantsService } from './services/constants.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AppGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private storage: StorageService,
        private consts: ConstantsService
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        let url: string = state.url;
        return this.checkLogin(url);
    }
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
    checkLogin(url: string): boolean {
        var val = this.storage.GetAuthorizationData();
        if (val != null) { return true; }
        this.authService.redirectUrl = url;
        this.router.navigate([this.consts.adminPanelLinkStr]);
        return false;
    }
}