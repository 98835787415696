<!-- header hero -->

<div
  id="home"
  class="header-hero bg_cover d-lg-flex align-items-center wow fadeIn"
  style="
    background-image: url(assets/zeely/Tulut.png);
    background-size: contain;
  "
></div>

<!-- header hero -->
