<!--[if IE]>
  <p class="browserupgrade">
    You are using an <strong>outdated</strong> browser. Please
    <a href="https://browsehappy.com/">upgrade your browser</a> to improve your
    experience and security.
  </p>
<![endif]-->

<!--====== PRELOADER PART START ======-->

<!-- <div class="preloader">
  <div class="loader">
    <div class="ytp-spinner">
      <div class="ytp-spinner-container">
        <div class="ytp-spinner-rotator">
          <div class="ytp-spinner-left">
            <div class="ytp-spinner-circle"></div>
          </div>
          <div class="ytp-spinner-right">
            <div class="ytp-spinner-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!--====== PRELOADER PART ENDS ======-->

<!--====== HEADER PART START ======-->

<header class="header-area">
  <div class="navbar-area headroom">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="index.html">
              <img src="assets/zeely/logo.png" alt="Logo" />
            </a>

            <div class="collapse navbar-collapse sub-menu-bar">
              <ul id="nav" class="navbar-nav m-auto">
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/zeely">{{
                    "layout.menu.zeely" | translate
                  }}</a>
                </li>
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/tuluy">{{
                    "layout.menu.tuluy" | translate
                  }}</a>
                </li>
              </ul>
            </div>

            <div class="collapse navbar-collapse sub-menu-bar">
              <ul id="nav" class="navbar-nav m-auto">
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/about">{{
                    "layout.menu.about" | translate
                  }}</a>
                </li>
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/info-sec">{{
                    "layout.menu.policy" | translate
                  }}</a>
                </li>
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/whistle">{{
                    "layout.menu.whistle" | translate
                  }}</a>
                </li>
                <li routerLinkActive="active" class="nav-item">
                  <a routerLink="/help">{{ "layout.menu.help" | translate }}</a>
                </li>
              </ul>
            </div>

            <div class="navbar-btn d-none d-lg-inline-block btn-hide">
              <a
                class="main-btn main-btn-4"
                style="padding-inline: 20px"
                data-scroll-nav="0"
                routerLink="/blog"
                >{{ "layout.menu.learn" | translate }}</a
              >
            </div>
            <div style="width: 20px"></div>
            <div class="navbar-btn d-none d-lg-inline-block btn-hide">
              <a
                class="main-btn main-btn-3"
                style="padding-inline: 20px"
                data-scroll-nav="0"
                routerLink="/scoring"
                >{{ "layout.menu.scoring" | translate }}</a
              >
            </div>
            <div style="width: 20px"></div>
            <div class="navbar-btn d-none d-lg-inline-block btn-hide">
              <a
                data-scroll-nav="0"
                href="#"
                (click)="showModalMiddle()"
                nz-popover
                [nzPopoverContent]="contentTemplate"
                nzPopoverPlacement="bottom"
                ><img
                  src="assets/zeely/download-square.png"
                  width="40"
                  height="40"
                  alt="download"
              /></a>
              <ng-template
                #contentTemplate
                [style]="{ backgroundColor: '#0E0F0F' }"
              >
                <diV style="margin: 0; padding: 0; background-color: #fff">
                  <ul style="margin: 0; padding: 0">
                    <li
                      style="
                        background-color: #a9b0bb;
                        width: 125px;
                        height: 40px;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 15px;
                        margin-top: 10px;
                        list-style: none;
                      "
                    >
                      <a
                        href="https://apps.apple.com/mn/app/zeely-by-optimal/id1419637942"
                        target="_blank"
                        style="font-size: 13px"
                        ><img src="assets/zeely/apple.png" alt="Logo" />
                        <span
                          style="
                            padding-left: 10px;
                            vertical-align: middle;
                            color: #fff;
                          "
                          >{{ "layout.app.appstore" | translate }}</span
                        ></a
                      >
                    </li>
                    <li
                      style="
                        background-color: #a9b0bb;
                        width: 125px;
                        height: 40px;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 15px;
                        margin-top: 10px;
                        list-style: none;
                      "
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=mn.fr099y.optimal"
                        target="_blank"
                        style="font-size: 13px"
                        ><img src="assets/zeely/android.png" alt="Logo" />
                        <span
                          style="
                            padding-left: 10px;
                            vertical-align: middle;
                            color: #fff;
                          "
                          >{{ "layout.app.playstore" | translate }}</span
                        ></a
                      >
                    </li>
                    <li
                      style="
                        background-color: #a9b0bb;
                        width: 125px;
                        height: 40px;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 15px;
                        margin-top: 10px;
                        list-style: none;
                      "
                    >
                      <a
                        href="https://appgallery.huawei.com/app/C104445313"
                        target="_blank"
                        style="font-size: 13px"
                        ><img src="assets/zeely/huawei.png" alt="Logo" />
                        <span
                          style="
                            padding-left: 10px;
                            vertical-align: middle;
                            color: #fff;
                          "
                          >{{ "layout.app.huawei" | translate }}</span
                        ></a
                      >
                    </li>
                  </ul>
                </diV>
              </ng-template>
            </div>
            <div style="width: 100px; display: flex; justify-content: center">
              <ul id="nav" class="navbar-nav m-auto">
                <li class="nav-item">
                  <a (click)="changeLang(langTxt)" style="color: #2d66b1">{{
                    langTxt
                  }}</a>
                </li>
              </ul>
            </div>

            <div class="btn-hide">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div
                class="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" class="navbar-nav">
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/zeely">{{
                      "layout.menu.zeely" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/tuluy">{{
                      "layout.menu.tuluy" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/about">{{
                      "layout.menu.about" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/info-sec">{{
                      "layout.menu.policy" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/help">{{
                      "layout.menu.help" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/blog">{{
                      "layout.menu.learn" | translate
                    }}</a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                    <a routerLink="/scoring">{{
                      "layout.menu.scoring" | translate
                    }}</a>
                  </li>
                  <li class="nav-item">
                    <a (click)="changeLang(langTxt)">{{ langTxt }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <!-- navbar -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
  <!-- navbar area -->
</header>

<!--====== HEADER PART ENDS ======-->

<router-outlet></router-outlet>

<!--====== CONTACT PART START ======-->

<div
  id="home"
  class="header-hero bg_cover d-lg-flex align-items-center"
  style="background-image: url(assets/zeely/footer_bg.png)"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="header-hero-content">
          <h4
            class="hero-title wow fadeInUp"
            style="color: white"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <b>{{ "layout.contact.online" | translate }}</b>
          </h4>
          <p
            class="text wow fadeInUp"
            style="color: white"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            {{ "layout.contact.desc" | translate }}
          </p>
          <div
            class="gallery-btn mt-60 footer-link wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <ul style="display: inline-flex; padding: 0">
              <li
                style="
                  margin: 0 5px;
                  background-color: #0e0f0f;
                  width: 125px;
                  height: 40px;
                  text-align: center;
                  line-height: 35px;
                  border-radius: 15px;
                "
              >
                <a
                  href="https://apps.apple.com/mn/app/zeely-by-optimal/id1419637942"
                  target="_blank"
                  style="font-size: 13px"
                  ><img src="assets/zeely/apple.png" alt="Logo" />
                  <span style="padding-left: 10px; vertical-align: middle">{{
                    "layout.app.appstore" | translate
                  }}</span></a
                >
              </li>
              <li
                style="
                  margin: 0 5px;
                  background-color: #0e0f0f;
                  width: 125px;
                  height: 40px;
                  text-align: center;
                  line-height: 35px;
                  border-radius: 15px;
                "
              >
                <a
                  href="https://play.google.com/store/apps/details?id=mn.fr099y.optimal"
                  target="_blank"
                  style="font-size: 13px"
                  ><img src="assets/zeely/android.png" alt="Logo" />
                  <span style="padding-left: 10px; vertical-align: middle">{{
                    "layout.app.playstore" | translate
                  }}</span></a
                >
              </li>
            </ul>
            <ul style="display: inline-flex; padding: 0">
              <li
                style="
                  margin: 0 5px;
                  background-color: #0e0f0f;
                  width: 125px;
                  height: 40px;
                  text-align: center;
                  line-height: 35px;
                  border-radius: 15px;
                "
              >
                <a
                  href="https://appgallery.huawei.com/app/C104445313"
                  target="_blank"
                  style="font-size: 13px"
                  ><img src="assets/zeely/huawei.png" alt="Logo" />
                  <span style="padding-left: 10px; vertical-align: middle">{{
                    "layout.app.huawei" | translate
                  }}</span></a
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- header hero content -->
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
  <div
    class="header-hero-image d-flex align-items-center wow fadeInRightBig"
    data-wow-duration="1s"
    data-wow-delay="1.1s"
  >
    <div class="image">
      <img
        src="assets/zeely/double-iphone-x.png"
        style="width: 397px; height: 535px"
        alt="Hero Image"
      />
    </div>
  </div>
  <!-- header hero image -->
</div>

<!--====== CONTACT PART ENDS ======-->

<!--====== FOOTER PART START ======-->

<footer
  id="footer"
  class="footer-area bg_cover"
  style="background-color: #0e0f0f"
>
  <div class="container">
    <div class="footer-widget pt-30 pb-30">
      <div class="row footer-link">
        <div class="col-lg-2 footer-hide">
          <a href="#" style="font-size: 14px"
            ><img src="assets/zeely/logo.png" alt="Logo"
          /></a>
        </div>
        <div class="col-lg-6 footer-hide">
          <ul style="display: inline-flex">
            <li style="margin-top: 5px; padding: 0 20px">
              <a routerLink="zeely" style="font-size: 14px">{{
                "layout.menu.zeely" | translate
              }}</a>
            </li>
            <li style="margin-top: 5px; padding: 0 20px">
              <a routerLink="tuluy" style="font-size: 14px">{{
                "layout.menu.tuluy" | translate
              }}</a>
            </li>
            <li style="margin-top: 5px; padding: 0 20px">
              <a routerLink="about" style="font-size: 14px">{{
                "layout.menu.about" | translate
              }}</a>
            </li>
            <li style="margin-top: 5px; padding: 0 20px">
              <a routerLink="help" style="font-size: 14px">{{
                "layout.menu.help" | translate
              }}</a>
            </li>
            <li style="margin-top: 5px; padding: 0 20px">
              <a routerLink="blog" style="font-size: 14px">{{
                "layout.menu.learn" | translate
              }}</a>
            </li>
          </ul>
        </div>
        <diV class="col-lg-4 footer-hide" style="text-align: center">
          <ul style="display: inline-flex; padding: 0">
            <li
              style="
                margin: 0 5px;
                background-color: #383a3a;
                width: 120px;
                height: 35px;
                text-align: center;
                line-height: 29px;
                border-radius: 15px;
              "
            >
              <a
                href="https://apps.apple.com/mn/app/zeely-by-optimal/id1419637942"
                target="_blank"
                style="font-size: 11px"
                ><img src="assets/zeely/apple.png" alt="Logo" />
                <span style="padding-left: 10px; vertical-align: middle">{{
                  "layout.app.appstore" | translate
                }}</span></a
              >
            </li>
            <li
              style="
                margin: 0 5px;
                background-color: #383a3a;
                width: 120px;
                height: 35px;
                text-align: center;
                line-height: 29px;
                border-radius: 15px;
              "
            >
              <a
                href="https://play.google.com/store/apps/details?id=mn.fr099y.optimal"
                target="_blank"
                style="font-size: 11px"
                ><img src="assets/zeely/android.png" alt="Logo" />
                <span style="padding-left: 10px; vertical-align: middle">{{
                  "layout.app.playstore" | translate
                }}</span></a
              >
            </li>
            <li
              style="
                margin: 0 5px;
                background-color: #383a3a;
                width: 120px;
                height: 35px;
                text-align: center;
                line-height: 29px;
                border-radius: 15px;
              "
            >
              <a
                href="https://appgallery.huawei.com/app/C104445313"
                target="_blank"
                style="font-size: 11px"
                ><img src="assets/zeely/huawei.png" alt="Logo" />
                <span style="padding-left: 10px; vertical-align: middle">{{
                  "layout.app.huawei" | translate
                }}</span></a
              >
            </li>
          </ul>
        </diV>
      </div>
      <!-- row -->
    </div>
    <!-- footer widget -->
    <div class="footer-copyright text-center">
      <div class="row">
        <div class="col-lg-4">
          <p class="text" style="font-size: 14px">
            © 2024
            <a href="https://zeely.mn" rel="nofollow" style="color: #2d66b1">{{
              "layout.footer.zeely" | translate
            }}</a>
          </p>
        </div>
        <div class="col-lg-8 footer-link">
          <ul style="display: inline-flex">
            <li style="margin-top: 0; padding: 0 20px">
              <a routerLink="service-info" style="font-size: 14px">{{
                "layout.footer.service" | translate
              }}</a>
            </li>
            <!-- <li style="margin-top: 0;padding: 0 20px;"><a routerLink="policy"
                                style="font-size: 14px;">Нууцлалын бодлого</a></li> -->
            <!-- <li style="margin-top: 0;padding: 0 20px;"><a routerLink="accessibility"
                                style="font-size: 14px;">Accessibility</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- container -->
</footer>

<!--====== FOOTER PART ENDS ======-->

<!--====== BACK TOP TOP PART START ======-->

<a href="#" class="back-to-top" style="background-color: #2d66b1"
  ><i class="lni-chevron-up"></i
></a>

<!--====== BACK TOP TOP PART ENDS ======-->
