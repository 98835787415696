<section id="blog" class="blog-area pt-120">
  <div class="container about-area pb-80">
    <div
      class="row testimonial-right-content mt-20 wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <div class="single-testimonial" *ngIf="blogMain">
          <div
            class="testimonial-text mb-20"
            style="
              color: #2d66b1;
              font-size: 30px;
              font-weight: bold;
              text-align: center;
            "
          >
            {{ blogMain.title }}
          </div>
          <div class="row d-sm-flex justify-content-between">
            <div class="col-lg-6 author-info d-flex align-items-center">
              <div class="author-image">
                <img src="{{ blogMain.user }}" alt="author" width="40" />
              </div>
              <div class="testimonial-text" style="padding-left: 15px">
                <p
                  style="
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 0.6;
                  "
                >
                  {{ blogMain.name }}
                </p>
                <span style="font-size: 12px; color: #a9b0bb">{{
                  "detail.main.niitelsen" | translate
                }}</span>
              </div>
            </div>
            <div class="col-lg-6" style="margin-top: 0; text-align: right">
              <img
                src="assets/zeely/alarm-clock.png"
                alt="blog-time"
                width="30"
              />
              <span
                style="margin-left: 10px; font-size: 12px; color: #a9b0bb"
                >{{ blogMain.date | date : "yyyy.MM.dd" }}</span
              >
            </div>
          </div>
          <div class="mb-20 mt-20">
            <img
              src="{{ blogMain.coverPhoto }}"
              alt="blog"
              width="100%"
              style="border-radius: 15px"
            />
          </div>
          <p
            class="mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
            innerHTML="{{ blogMain.text }}"
          ></p>
          <div class="row">
            <div class="col-lg-6 single-testimonial-span">
              <span *ngFor="let tag of blogMain.tags">#{{ tag.name }}</span>
            </div>
            <div class="col-lg-6" style="text-align: right">
              <img src="assets/zeely/social-ico.png" alt="social" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container blog-area about-area pb-80">
    <div class="row justify-content-center">
      <div class="col-lg-9 pb-40">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "detail.main.news" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div
      class="row testimonial-right-content mt-20 wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
      *ngIf="blogDatas"
    >
    <div class="col-lg-4 col-sm-6 mb-20 my-custom-content-item {{  blog.tagStr }} "
        *ngFor="let blog of blogDatas?.BlogList | slice : 0 : 3" >
        <div class="blog-gallery gallery-masonry wow fadeIn">
          <div class="gallery-image">
            <img src="{{ blog.photo }}" alt="blog" height="464"/>
            <div class="blog-testimonial">
              </div>
              <div class="blog-card">
                <div class="testimonial-text" style="color: #fff; ">
                  <a style="color: white; font-size: 16px; font-weight: 700;position: absolute; bottom: 115px;" 
                    [routerLink]="[blogDetailLink, blog.blogId]"
                    >{{ blog.title }}</a>
                </div>
                  <span style="position: absolute; bottom: 79px;">{{"detail.main.niitelsen" | translate}}: {{
                    blog.date | date : "yyyy.MM.dd"  }}</span>
                    <a  [routerLink]="[blogDetailLink, blog.blogId]">
                      <div class="single-testimonial-span">
                        <span *ngFor="let tag of blog.tags">#{{ tag.name }}</span>
                        <div class="arrow">
                          ➜
                        </div>
                      </div>
                    </a>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</section>
