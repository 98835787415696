export class Signin {
    public userId: Number;
    public username: String;
    public token: String;

    constructor(signinStr: any) {
        this.userId = signinStr.userId;
        this.username = signinStr.username;
        this.token = signinStr.token;
    }
}

export class User {
    public userId: Number;
    public firstName: String;

    constructor(userStr: any) {
        this.userId = userStr.userId;
        this.firstName = userStr.firstName;
    }
}