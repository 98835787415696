import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './layouts/main/main.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import mn from '@angular/common/locales/mn';
import { AppNzModule } from './app.nz.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService, API_BASE_URL, ConfigFactory } from './services/config';
import { InterceptorService } from './services/interceptor.service';
import { InfosecComponent } from './pages/infosec/infosec.component';
import { PrivacyPolicyComponent } from './pages/policy/privacy-policy/privacy-policy.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { PlayOnViewportDirective } from 'src/app/services/play-on-viewport.directive';
import { AdminModule } from './admin/admin.module';



registerLocaleData(mn);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,
    PrivacyPolicyComponent,
    PlayOnViewportDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppNzModule,
    MainModule,
    FormsModule,
    AdminModule,
    ReactiveFormsModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    YouTubePlayerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    ConfigService,
    { provide: 'CONFIG.JSON', useValue: './assets/config.json' },
    { provide: 'BASE-API-VARIABLE', useValue: 'API_URL' },
    {
      provide: API_BASE_URL, useFactory: ConfigFactory,
      deps: [ConfigService, 'CONFIG.JSON', 'BASE-API-VARIABLE']
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule { }