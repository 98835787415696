import { Component, TemplateRef } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-whistle",
  templateUrl: "./whistle.component.html",
  styleUrls: ["./whistle.component.less"],
})
export class WhistleComponent {
  constructor(private modal: NzModalService) {}

  showModal(contentTemplate: TemplateRef<{}>): void {
    this.modal.create({
      nzContent: contentTemplate,
      nzFooter: null,
      nzCentered: true,
      nzClassName: "custom-modal",
    });
  }

  closeModal(): void {
    this.modal.closeAll();
  }

  openForm() {
    window.open(
      "https://docs.google.com/forms/d/1JUrxVak45SjuE6zZQqxlhzvzXWbAnBt63eIGsIQB02k/viewform?edit_requested=true",
      "_blank"
    );
    this.closeModal();
  }
}
