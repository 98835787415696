<!--====== BLOG PART START ======-->

<section id="portfolio" class="blog-area pt-100 project-masonry-area">
  <div class="header-container">
    <div class="container">
      <div class="row">
        <div
          class="about-title wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span>{{ "layout.menu.news" | translate }}</span>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <div class="container mt-20" style="margin-bottom: 20px">
      <div class="row wrap-row" style="align-items: start">
        <!-- Цэс -->
        <div class="col-lg-2">
          <div class="list-group">
            <a
              class="nav-link"
              [class]="page == 1 ? 'active' : ''"
              (click)="changePage(1)"
              class="list-group-item list-group-item-action"
              >{{ "detail.main.news" | translate }}</a
            >
            <a
              class="nav-link"
              [class]="page == 2 ? 'active' : ''"
              (click)="changePage(2)"
              class="list-group-item list-group-item-action"
              >{{ "detail.main.report" | translate }}</a
            >
            <a
              class="nav-link"
              [class]="page == 3 ? 'active' : ''"
              (click)="changePage(3)"
              class="list-group-item list-group-item-action"
              >{{ "detail.main.rule" | translate }}</a
            >
          </div>
        </div>
        <!-- Мэдээ -->
        <div class="col-lg-10" *ngIf="page === 1">
          <div
            class="testimonial-right-content wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.6s"
          >
            <div class="head">
              <div class="project-menu text-center slick-slider">
                <ul>
                  <li
                    [class]="isAll == true ? 'active' : ''"
                    (click)="getAllBlogs()"
                  >
                    Бүгд
                  </li>
                  <li
                    *ngFor="let tag of tagDatas; let i = index"
                    (click)="getTagFilter(tag, i)"
                    [class]="
                      i == selectedTag && isAll == false
                        ? 'active m_title'
                        : 'm_title'
                    "
                  >
                    {{ tag.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="special-cont wow fadeIn"
              *ngIf="specialBlog"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <img
                style="opacity: 0.5"
                src="{{ specialBlog?.coverPhoto }}"
                alt="blog"
              />
              <div class="special-desc">
                <span>{{ specialBlog.title }}</span>
                <div class="row align-items-center" style="margin: 0%">
                  <div
                    class="col-1"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <div class="author-image">
                      <img src="{{ specialBlog.user }}" />
                    </div>
                  </div>
                  <div
                    class="col-11 author-info"
                    style="
                      padding-left: 0px;
                      padding-right: 0px;
                      margin-top: 20px;
                    "
                  >
                    <p>{{ specialBlog.name }}</p>
                    <p style="font-size: 16px; font-weight: 400">
                      {{ "detail.main.niitelsen" | translate }}
                    </p>
                  </div>
                </div>
                <div
                  class="row justify-content-between"
                  style="margin-top: 10%"
                >
                  <div
                    class="col-8"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <div class="single-testimonial-span">
                      <span *ngFor="let tag of specialBlog.tags"
                        >#{{ tag.name }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-3"
                    style="padding-left: 0px; padding-right: 0px"
                  >
                    <p
                      style="
                        font-size: 16px;
                        font-weight: 400;
                        margin-top: 30px;
                      "
                    >
                      {{ "detail.main.niitelsen" | translate }}:
                      {{ specialBlog.date | date : "yyyy.MM.dd" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row my-custom-content mt-20"
              *ngIf="blogDatas?.BlogList"
            >
              <div
                class="col-lg-4 col-sm-6 mb-20 my-custom-content-item {{
                  blog.tagStr
                }} "
                *ngFor="let blog of blogDatas?.BlogList"
              >
                <div class="single-gallery gallery-masonry wow fadeIn">
                  <div class="gallery-image">
                    <img
                      src="{{ blog.photo }}"
                      alt="blog"
                      width="332"
                      height="464"
                    />
                    <div class="single-testimonial"></div>
                    <div class="blog-card">
                      <div class="testimonial-text" style="color: #fff">
                        <a
                          style="
                            color: white;
                            font-size: 16px;
                            font-weight: 700;
                            position: absolute;
                            bottom: 115px;
                          "
                          [routerLink]="[blogDetailLink, blog.blogId]"
                          >{{ blog.title }}</a
                        >
                      </div>
                      <span style="position: absolute; bottom: 79px"
                        >{{ "detail.main.niitelsen" | translate }}:
                        {{ blog.date | date : "yyyy.MM.dd" }}</span
                      >
                      <div
                        class="single-testimonial-span"
                        (click)="routeToDetail(blog.blogId)"
                      >
                        <span *ngFor="let tag of blog.tags"
                          >#{{ tag.name }}</span
                        >
                        <div class="arrow">➜</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Тайлан -->
        <div class="col-lg-10" *ngIf="page === 2">
          <div class="report-type text-center slick-slider">
            <ul>
              <li
                *ngFor="let type of reportTypies; let i = index"
                (click)="changeReportType(type.value)"
                [class]="type.value === selectedReportType ? 'active' : ''"
              >
                {{ type.name | translate }}
              </li>
            </ul>
          </div>
          <div id="accordion" *ngIf="reports">
            <div class="card" *ngFor="let report of reports">
              <div class="card-header" id="{{ report.reportYear }}">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    data-toggle="collapse"
                    (click)="
                      tapClick(
                        showCollapse === report.reportYear
                          ? 0
                          : report.reportYear
                      )
                    "
                  >
                    {{ report.reportYear }}
                    {{
                      selectedReportType === "Activity"
                        ? ("detail.main.yearOfReport1" | translate)
                        : ("detail.main.yearOfReport2" | translate)
                    }}
                  </button>
                </h5>
              </div>
              <div
                class="collapse {{
                  showCollapse === report.reportYear ? 'show' : 'collapsed'
                }} wow fadeIn"
              >
                <div class="card-body">
                  <div class="row" *ngFor="let item of report.reports">
                    <div class="col-lg-8" style="margin-left: 70px">
                      <img
                        src="assets/images/Vector.png"
                        height="18"
                        width="18"
                        style="margin-right: 20px"
                      />
                      <a
                        href="{{ item.filePath }}"
                        target="_blank"
                        style="color: white"
                      >
                        <span style="font-size: 16px; margin-right: 10px"
                          >{{ item.reportName }}
                        </span></a
                      >
                    </div>
                    <div class="col-lg-2">
                      <a
                        href="{{ item.filePath }}"
                        download=""
                        style="color: white"
                        target="_blank"
                      >
                        <span style="font-size: 16px; margin-right: 10px"
                          >Татах</span
                        >
                        <i class="lni-download"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Дүрэм журам -->
        <div class="col-lg-10" *ngIf="page === 3">
          <div class="report-type text-center slick-slider">
            <ul>
              <li
                *ngFor="let type of ruleTypies; let i = index"
                (click)="changeRuleType(type.value)"
                [class]="type.value === selectedRuleType ? 'active' : ''"
              >
                {{ type.name | translate }}
              </li>
            </ul>
          </div>
          <div id="accordion" *ngIf="rules">
            <div class="card" *ngFor="let rule of rules; let i = index">
              <div class="card-header" id="{{ rule.ruleId }}">
                <div class="row">
                  <div class="col-lg-10">
                    <span style="font-size: 16px; margin-right: 20px">
                      {{ i + 1 }}.
                    </span>
                    <a
                      href="{{ rule.filePath }}"
                      target="_blank"
                      style="color: white"
                    >
                      <span style="font-size: 16px; margin-right: 10px">
                        {{ rule.ruleName }}
                      </span>
                    </a>
                  </div>
                  <div class="col-lg-2">
                    <a
                      href="{{ rule.filePath }}"
                      download=""
                      style="color: white"
                      target="_blank"
                    >
                      <span style="font-size: 16px; margin-right: 10px"
                        >Татах</span
                      >
                      <i class="lni-download"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--====== BLOG PART ENDS ======-->
