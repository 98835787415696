export class Feedback {
    public name: String;
    public feedback: String;
    public mail: String;

    constructor(feedStr: any) {
        this.name = feedStr.name;
        this.feedback = feedStr.feedback;
        this.mail = feedStr.mail;
    }
}
