<section id="blog" class="blog-area pt-180">
  <div class="container about-area pb-80">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "whistle.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->

    <div
      class="row testimonial-right-content wow fadeIn mt-40"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="container about-area pb-80">
        <div
          class="row testimonial-right-content wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.6s"
        >
          <div class="col-lg-12 mb-50 business">
            <div class="single-testimonial">
              <div
                class="testimonial-text mb-10"
                style="
                  color: #fff;
                  text-align: center;
                  line-height: 30px;
                  padding: 0 50px;
                  font-weight: bold;
                  text-align: left;
                "
              >
                {{ "whistle.header" | translate }}
              </div>
              <div
                class="testimonial-text mb-10"
                style="
                  color: #fff;
                  text-align: center;
                  line-height: 30px;
                  padding: 0 50px;
                  font-weight: bold;
                  text-align: left;
                "
              >
                {{ "whistle.subHeader" | translate }}
              </div>
              <div
                class="testimonial-text mb-20"
                style="
                  color: #fff;
                  text-align: justify;
                  line-height: 30px;
                  padding: 0px 50px;
                "
              >
                <ul>
                  <li>{{ "whistle.desc1" | translate }}</li>
                  <li>{{ "whistle.desc2" | translate }}</li>
                  <li>{{ "whistle.desc3" | translate }}</li>
                  <li>{{ "whistle.desc4" | translate }}</li>
                  <li>{{ "whistle.desc5" | translate }}</li>
                  <li>{{ "whistle.desc6" | translate }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <button class="main-btn mainbtn" (click)="showModal(contentTemplate)">
            Шүгэл үлээх
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template d #contentTemplate>
  <div style="margin: 0; padding: 0">
    <div
      class="testimonial-text mb-20"
      style="
        color: #1f4577;
        font-weight: bold;
        text-align: left;
        font-size: 17px;
      "
    >
      {{ "whistle.modal.title" | translate }}
    </div>
    <div
      class="testimonial-text mb-20"
      style="color: #235aa1; text-align: justify; font-weight: 700"
    >
      <ul>
        <li>{{ "whistle.modal.desc1" | translate }}</li>
        <li>{{ "whistle.modal.desc2" | translate }}</li>
        <li>
          Би компанийн үйл ажиллагааг засаж сайжруулахын төлөө шүгэл үлээж
          байгаадаа итгэлтэй байна. "Хэрвээ таны илгээх гэж буй мэдээлэл зөрчил
          мөн эсэхийг мэдэхгүй байгаа бол та
          <a
            style="text-decoration: underline"
            target="_blank"
            href="mailto:compliance@zeely.mn"
            >compliance@zeely.mn</a
          >
          и-мэйл хаягт хандан зөвлөгөө авч болно. Таны шүгэл үлээх гэж буй
          мэдээлэл зөрчил мөн эсэхэд эргэлзэж байгаа бол
          <a
            href="https://ftzurag.s3-ap-southeast-1.amazonaws.com/1/report/19c6a7d3-4c18-427a-935b-d5819ab691b7.pdf"
            target="_blank"
            style="text-decoration: underline"
            >Ёс зүйн кодекс линкээр</a
          >
          линкээр орж уншиж танилцаарай.
        </li>
      </ul>
    </div>
    <div style="display: flex; justify-content: center">
      <button class="main-btn mainbtn" (click)="openForm()">
        Зөвшөөрч байна
      </button>
    </div>
  </div>
</ng-template>
