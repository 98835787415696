import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstantsService } from '../constants.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    constructor(
        private http: HttpClient,
        private _const: ConstantsService
    ) { }

    getPosts(): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("blog-posts"));
    }

    getPostsPlus(): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("blog-posts/plus"));
    }
    getPost(id: any): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("blog-post/" + id));
    }
    getPostByTag(val: any): Observable<any> {
        return this.http.get<any>(this._const.apiUrl("blog-post-tag/?tag=" + val));
    }
}