import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsService } from './services/constants.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Zeely.mn';
  constructor(
    private translateService: TranslateService,
    private storage: StorageService,
    private consts: ConstantsService,
  ) {
    var lang = this.storage.GetLang();
    if (lang == undefined) {
      lang = this.consts.langEnStr;
      this.storage.SetLang(lang);
    }
    this.translateService.use(lang);
  }
}