import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Blog, Blogs, Tag, Tags } from 'src/app/models/blog';
import { ReportList, RuleModel } from 'src/app/models/models';
import { BlogService } from 'src/app/services/blog/blog.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent implements OnInit {

  blogDetailLink: string = "";
  specialBlog: Blog | undefined;
  blogDatas: Blogs | undefined;
  tagDatas: Tag[] = [];
  tagMain: Tag[] = [];
  TagStr: any;
  selectedTag: any;
  selectedReportType : any = 'Activity';
  selectedRuleType : any = 'Rule';
  isAll: boolean = true;
  page = 1;
  showCollapse :number| undefined;

  reports : Array<ReportList> | undefined;
  rules : Array<RuleModel> | undefined;

  reportTypies : Array<{name:string, value: string}> = [
    {
      name:'detail.main.operational',
      value: 'Activity'
    },
    {
      name:'detail.main.financial',
      value: 'Finance'
    }
  ];
  ruleTypies : Array<{name:string, value: string}> = [
    {
      name:'detail.main.rules',
      value: 'Rule'
    },
    {
      name:'detail.main.regulation',
      value: 'Policy'
    }
  ]

  constructor(
    private _const: ConstantsService,
    private _blog: BlogService,
    private report: ReportService,
    private router: Router,
    ) {
    this.blogDetailLink = this._const.routerUrl(this._const.blogStr);
  }


  ngOnInit(): void {
    this._blog.getPostsPlus()
      .subscribe(
        data => {
          this.blogDatas = new Blogs(data.blogs);
          this.tagDatas = data.tags as Tag[];
          this.specialBlog = this.blogDatas.BlogList.filter((x) => x.isSpecial === true)[0];
          console.log(this.specialBlog)
          this.isAll = true;
        },
        error => { console.log(error); }
      );
  }
  routeToDetail(id : any ) {
    this.router.navigateByUrl(this.blogDetailLink = this._const.routerUrl(this._const.blogStr) +'/'+ id);
  }
  getAllBlogs() {
    this._blog.getPostsPlus()
      .subscribe(
        data => {
          this.blogDatas = new Blogs(data.blogs);
          this.tagDatas = data.tags as Tag[];

          this.isAll = true;

        },
        error => { console.log(error); }
      );
  }
  getTagFilter(val: any, i: number) {
    this._blog.getPostByTag(val.name)
      .subscribe(
        data => {
          this.blogDatas = new Blogs(data);
          this.selectedTag = i;
          this.isAll = false;
        },
        error => { console.log(error); }
      );
  }
  changePage(number: any) {
    this.page = number;
    if(number ===2){
      this.selectedReportType = 'Activity';
      this.report.getReports(this.selectedReportType)
      .subscribe(
        data => {
          this.reports = data;
          console.log(this.reports)
        },
        error => { console.log(error); }
      );
    }else if(number ===3){
      this.selectedRuleType = 'Rule';
      this.report.getRules(this.selectedRuleType)
      .subscribe(
        data => {
          this.rules = data;
        },
        error => { console.log(error); }
      );
    }
  }
  changeReportType(type : string){
    this.selectedReportType = type; 
    this.report.getReports(type)
    .subscribe(
      data => {
        this.reports = data;
      },
      error => { console.log(error); }
    );
  }
  changeRuleType(type : string){
    this.selectedRuleType = type; 
    this.report.getRules(type)
    .subscribe(
      data => {
        this.rules = data;
      },
      error => { console.log(error); }
    );
  }
  tapClick(year : number){
    this.showCollapse= year;
  }
}
