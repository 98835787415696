import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.less']
})
export class HelpComponent implements OnInit {

  panels = [
    {
      active: true,
      name: 'Zeely аппликейшн гэж юу вэ?',
      content: 'Zeely аппликейшн нь таны сэтгэл зүйд тулгуурлан ямар нэгэн барьцаа хөрөнгө шаардалгүйгээр 7 хоногийн 24 цагт онлайнаар зээл авах үйлчилгээ юм.'
    },
    {
      active: false,
      name: 'Zeely аппликейшнийг хэрхэн татах вэ?',
      content: 'Android утастай бол та Playstore, iphone утастай бол та Appstore - оос Zeely аппликейшнаа татан суулгах боломжтой.'
    },
    {
      active: false,
      name: 'Zeely аппликейшнд хэрхэн хэрэглэгч болох вэ?',
      content: 'Та аппликейшнаа татаж суулгаад өөрийн хувийн мэдээллээ бөглөсний дараагаар танаас сэтгэл зүйн асуулт асуух болно.'
    },
    {
      active: false,
      name: 'Зээл авахад ямар шаардлага тавих вэ?',
      content: '18 нас хүрсэн Монгол улсын иргэн байх. Монгол банкны Зээлийн Мэдээллийн санд чанаргүй зээлийн түүхгүй байх.'
    },
    {
      active: false,
      name: 'Гэрээ хийхэд шаардлагатай бичиг баримт?',
      content: 'Зөвхөн иргэний үнэмлэх шаардлагатай.'
    },
    {
      active: false,
      name: 'Зээл хэрхэн авах вэ?',
      content: 'Таны зээлийн эрх үүссэний дараа та нэг удаагийн гэрээ байгуулаад хэзээ ч хаанаас ч зээл авах боломжтой болно.'
    },
    {
      active: false,
      name: 'Зээлийн хэмжээ, хугацаа, нөхцөл?',
      content: 'Зээл авах дүн: 50,000₮-500,000₮. Зээлийн жилийн хүү: 3% - 18%. Зээлийн өртөг: Сарын хүү 3%-6%. Шимтгэл болон сунгалт 5,000₮ - 7,000₮. Зээл төлөх хугацаа: 30-90 хоног. Та манай үйлчилгээний нөхцөлтэй дэлгэрэнгүй танилцахыг хүсвэл https://www.zeely.mn/service-info дарна уу.'
    },
    {
      active: false,
      name: 'Бүртгэлтэй утасны дугаараа хэрхэн солих вэ?',
      content: 'Та манай лавлах 75073000 гэсэн дугаараар залган бүртгэлтэй дугаараа солиулаарай.'
    },
    {
      active: false,
      name: 'Нууц үгээ мартсан бол яах вэ?',
      content: 'Аппликейшний нууц үг мартсан хэсгээр орж сэргээх боломжтой.'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}