<!--====== Service info START ======-->

<section id="about" class="about-area mt-130 mb-150">
  <div class="container about-area pb-40">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "service.main.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pb-80">
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <div class="single-testimonial">
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              "service.main.desc" | translate
            }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "service.main.ul.1" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.2" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.3" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.6" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.7" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.8" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "service.main.ul.9" | translate }}
              </li>
              <li>{{ "service.main.ul.10" | translate }}</li>
              <li>{{ "service.main.ul.11" | translate }}</li>
              <li>{{ "service.main.ul.12" | translate }}</li>
              <li>{{ "service.main.ul.13" | translate }}</li>
              <li>{{ "service.main.ul.14" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              "service.main.desc2" | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="about-area mt-130 mb-150">
  <div class="container about-area pb-40">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "policy.main.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container about-area pb-80">
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12 mb-50 business">
        <div class="single-testimonial">
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.1.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.1.1" | translate }}</li>
              <li>{{ "policy.main.1.2" | translate }}</li>
              <li>{{ "policy.main.1.3" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "policy.main.1.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.1.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.1.6" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.1.7" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.1.8" | translate }}
              </li>
              <li>{{ "policy.main.1.9" | translate }}</li>
              <li>{{ "policy.main.1.10" | translate }}</li>
              <li>{{ "policy.main.1.11" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.2.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.2.1" | translate }}</li>
              <li>{{ "policy.main.2.2" | translate }}</li>
              <li>{{ "policy.main.2.3" | translate }}</li>
              <li>{{ "policy.main.2.4" | translate }}</li>
              <li>{{ "policy.main.2.5" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.3.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.3.1" | translate }}</li>
              <li>{{ "policy.main.3.2" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "policy.main.3.3" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.3.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.3.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.3.6" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.3.7" | translate }}
              </li>
              <li>{{ "policy.main.3.8" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.4.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.4.1" | translate }}</li>
              <li>{{ "policy.main.4.2" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.5.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.5.1" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.2" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.3" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.6" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.7" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.8" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.5.9" | translate }}
              </li>
              <li>{{ "policy.main.5.10" | translate }}</li>
              <li>{{ "policy.main.5.11" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.6.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.6.1" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.2" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.3" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.6" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.7" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.6.8" | translate }}
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.7.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.7.1" | translate }}</li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.8.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.8.1" | translate }}</li>
              <li style="padding-left: 50px">
                {{ "policy.main.8.2" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.8.3" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.8.4" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.8.5" | translate }}
              </li>
              <li style="padding-left: 50px">
                {{ "policy.main.8.6" | translate }}
              </li>
              <li>
                {{ "policy.main.8.7" | translate }}
              </li>
              <li>
                {{ "policy.main.8.8" | translate }}
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.9.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.9.1" | translate }}</li>
              <li>
                {{ "policy.main.9.2" | translate }}
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.10.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.10.1" | translate }}</li>
              <li>
                {{ "policy.main.10.2" | translate }}
              </li>
              <li>
                {{ "policy.main.10.3" | translate }}
              </li>
              <li>
                {{ "policy.main.10.4" | translate }}
              </li>
            </ul>
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: center;
              line-height: 30px;
              padding: 0 50px;
              font-weight: bold;
            "
          >
            {{ "policy.main.11.title" | translate }}
          </div>
          <div
            class="testimonial-text mb-20"
            style="
              color: #fff;
              text-align: justify;
              line-height: 30px;
              padding: 20px 50px;
            "
          >
            <ul class="hidden">
              <li>{{ "policy.main.11.1" | translate }}</li>
              <li>
                {{ "policy.main.11.2" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--====== Service info ======-->
