<!--====== ABOUT PART START ======-->
<section id="about" class="about-area pt-115">
  <div class="container mt-100">
    <div class="row justify-content-center">
      <ul class="nav nav-pills navs">
        <li class="nav-item">
          <a
            class="nav-link"
            [class]="page == 1 ? 'active' : ''"
            (click)="changePage(1)"
            >{{ "layout.menu.about" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [class]="page == 2 ? 'active' : ''"
            (click)="changePage(2)"
            >{{ "company.aboutCompany" | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</section>

<section id="about" class="about-area" *ngIf="page == 1">
  <div class="container" style="margin-top: 20px; margin-bottom: 20px">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="our-services-content mt-150 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div style="font-size: 28px; font-weight: 600; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #2d66b1">{{
              "about.main.title" | translate
            }}</span>
          </div>
          <div style="font-size: 24px; font-weight: 500; padding-bottom: 20px">
            <span style="padding-left: 20px; color: #ffffff">{{
              "about.main.desc2" | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row" style="align-items: center">
      <div class="col-lg-5">
        <div
          class="our-services-image mt-50 wow fadeInLeftBig"
          style="text-align: left"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="{{ 'about_images.who' | translate }}" alt="service" />
        </div>
      </div>
      <div class="col-lg-7 mt-50">
        <div
          class="our-services-content wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff; font-size: 20px">
                {{ "about.main.desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row wrap-row" style="align-items: center">
      <div class="col-lg-7 mt-50">
        <div
          class="our-services-content wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff; font-size: 20px">
                {{ "about.main.whatwe.desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: right"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="{{ 'about_images.whatwedo' | translate }}" alt="service" />
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row" style="align-items: center">
      <div class="col-lg-5">
        <div
          class="our-services-image mt-50 wow fadeInRightBig"
          style="text-align: right"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="{{ 'about_images.vision' | translate }}" alt="service" />
        </div>
      </div>
      <div class="col-lg-7 mt-50">
        <div
          class="our-services-content wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff; font-size: 20px">
                {{ "about.main.vision.desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100" style="margin-bottom: 20px">
    <div class="row wrap-row" style="align-items: center">
      <div class="col-lg-7 mt-50">
        <div
          class="our-services-content wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <div class="single-service d-flex" style="padding: 10px 20px 10px 0">
            <div class="service-content media-body">
              <p style="line-height: 26px; color: #fff; font-size: 20px">
                {{ "about.main.purpose.desc" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div
          class="our-services-image mt-50 wow fadeInLeftBig"
          style="text-align: left"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img src="{{ 'about_images.mission' | translate }}" alt="service" />
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="about-area mb-100" *ngIf="page == 2">
  <div class="container" style="margin-top: 70px; margin-bottom: 20px">
    <div
      style="
        font-size: 45px;
        font-weight: 600;
        padding-bottom: 20px;
        text-align: center;
      "
    >
      <span style="padding-left: 20px; color: #2d66b1">{{
        "company.aboutCompany" | translate
      }}</span>
    </div>
    <div style="font-size: 16px; font-weight: 500; padding-bottom: 20px">
      <span style="padding-left: 20px; color: #ffffff">{{
        "company.companyDesc" | translate
      }}</span>
    </div>
    <div
      class="our-services-image mt-50 wow fadeIn"
      style="text-align: left"
      data-wow-duration="1s"
      data-wow-delay="0.8s"
    >
      <img src="{{ 'company.structImage' | translate }}" alt="service" />
    </div>
  </div>
  <div class="container" style="margin-top: 70px; margin-bottom: 20px">
    <div
      style="
        font-size: 45px;
        font-weight: 600;
        padding-bottom: 20px;
        text-align: center;
      "
    >
      <span style="padding-left: 20px; color: #2d66b1">{{
        "company.headers" | translate
      }}</span>
    </div>
    <div style="font-size: 16px; font-weight: 500; padding-bottom: 20px">
      <span style="padding-left: 20px; color: #ffffff">{{
        "company.headersDesc" | translate
      }}</span>
    </div>
    <div
      class="row picture-container wow fadeIn justify-content-center"
      style="align-items: center"
    >
      <div
        class="col-lg-4 justify-content-center"
        *ngFor="let member of registerImages"
      >
        <div
          class="mt-50 pic"
          style="text-align: center"
          data-wow-duration="1s"
          data-wow-delay="0.8s"
        >
          <img [src]="member?.image" class="pic" alt="service" />
          <div class="pictire-card">
            <div class="dialogue">
              <span style="color: white; font-weight: 700">
                {{ member.position | translate }}
              </span>
              <p style="color: white">{{ member.name | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="margin-top: 70px; margin-bottom: 20px">
    <div
      style="
        font-size: 45px;
        font-weight: 600;
        padding-bottom: 20px;
        text-align: center;
      "
    >
      <span style="padding-left: 20px; color: #2d66b1">{{
        "company.headersCommitees" | translate
      }}</span>
    </div>

    <div
      class="row align-items-start text-center mt-50"
      style="align-items: center"
    >
      <div class="col-lg-4" *ngFor="let item of commitees">
        <span
          style="
            padding-bottom: 32px;
            color: #ffffff;
            font-size: 24px;
            font-weight: 600;
          "
          >{{ item.name | translate }}</span
        >
        <div class="commitee-card">
          <h2 style="color: #ffffff; font-size: 24px; font-weight: 700">
            {{ "company.chiefCommitee" | translate }}
          </h2>
          <img [src]="item.headImage" class="member-pic" alt="service" />
          <h2 style="padding-top: 12px; color: #ffffff; font-size: 16px">
            {{ item.head | translate }}
          </h2>
          <hr class="new1" />

          <h5
            style="
              padding-bottom: 20px;
              color: #ffffff;
              font-size: 24px;
              font-weight: 700;
            "
          >
            {{ "company.memberCommitee" | translate }}
          </h5>
          <div class="row text-center justify-content-around pr-30 pl-30">
            <div *ngFor="let mem of item.members">
              <div class="member-card">
                <img [src]="mem.image" class="member-pic" alt="service" />
                <h2 style="padding-top: 12px; color: #ffffff; font-size: 16px">
                  {{ mem.member | translate }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <span class="desc-text">{{ item.desc | translate }}</span>
      </div>
    </div>
  </div>
</section>

<!--====== ABOUT PART ENDS ======-->
