<!--====== PRICING PART START ======-->

<section id="blog" class="blog-area pt-180">
  <div class="container about-area pb-80">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "help.main.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->
  </div>

  <!--====== 3 ======-->
  <div class="container mb-150">
    <div
      class="row testimonial-right-content wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.6s"
    >
      <div class="col-lg-12">
        <nz-collapse
          nzAccordion
          nzExpandIconPosition="right"
          nzBordered="false"
        >
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.one' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.one" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.two' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.two" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.three' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.three" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.four' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.four" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.five' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.five" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.six' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.six" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.seven' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.seven" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.eight' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.eight" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.nine' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.nine" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.10' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.10" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.13' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.13" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.14' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.14" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.15' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.15" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.17' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.17" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.18' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.18" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.19' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.19" | translate }}
            </p>
          </nz-collapse-panel>
          <nz-collapse-panel
            class="ant-acc-header"
            nzHeader="{{ 'help.main.faq.question.20' | translate }}"
          >
            <p style="font-size: 16px; line-height: 28px; color: #fff">
              {{ "help.main.faq.answer.20" | translate }}
            </p>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </div>
  <div class="container about-area pb-80">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div
          class="about-title text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.4s"
        >
          <h3 class="title">
            <span style="color: #2d66b1">{{
              "help.main.contact.title" | translate
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div
          class="contact-wrapper-form pt-115 wow fadeInUpBig"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div class="row">
            <div class="col-md-6">
              <form id="contact-form" method="post">
                <div class="contact-form">
                  <input
                    type="text"
                    name="name"
                    placeholder="{{
                      'help.main.contact.form.firstname' | translate
                    }}"
                  />
                </div>
                <!-- contact-form -->
                <div class="contact-form mt-15">
                  <input
                    type="email"
                    name="email"
                    placeholder="{{
                      'help.main.contact.form.lastname' | translate
                    }}"
                  />
                </div>
                <!-- contact-form -->
                <div class="contact-form mt-15">
                  <input
                    type="email"
                    name="email"
                    placeholder="{{
                      'help.main.contact.form.email' | translate
                    }}"
                  />
                </div>
                <!-- contact-form -->
                <div class="contact-form mt-15">
                  <textarea
                    name="message"
                    placeholder="{{
                      'help.main.contact.form.message' | translate
                    }}"
                    style="height: 200px"
                  ></textarea>
                </div>
                <!-- contact-form -->
                <div class="contact-form mt-15 mb-15">
                  <a class="main-btn" href="#">{{
                    "help.main.contact.form.submit" | translate
                  }}</a>
                </div>
                <!-- contact-form -->
              </form>
            </div>
            <div class="col-md-6">
              <div
                class="single-testimonial"
                style="border-radius: 10px; padding: 20px"
              >
                <div class="d-sm-flex justify-content-between">
                  <div class="author-info d-flex align-items-center">
                    <img src="assets/zeely/location.png" alt="author" />
                    <p
                      class="text"
                      style="margin-bottom: 0; margin-left: 20px; color: #fff"
                    >
                      {{ "help.main.contact.address" | translate }}
                    </p>
                  </div>
                </div>
                <div class="d-sm-flex justify-content-between mt-25 mb-25">
                  <div class="author-info d-flex align-items-center">
                    <img src="assets/zeely/headset.png" alt="author" />
                    <p
                      class="text"
                      style="margin-bottom: 0; margin-left: 20px; color: #fff"
                    >
                      {{ "help.main.contact.phone" | translate }}
                    </p>
                  </div>
                </div>
                <div class="d-sm-flex justify-content-between">
                  <div class="author-info d-flex align-items-center">
                    <img src="assets/zeely/mail.png" alt="author" />
                    <p
                      class="text"
                      style="margin-bottom: 0; margin-left: 20px; color: #fff"
                    >
                      {{ "help.main.contact.email" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- contact wrapper form -->
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
</section>

<!--====== PRICING PART ENDS ======-->
